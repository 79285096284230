body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    --background-color: #eaeaea;
}

.image-gallery-image {
    max-height: 350px !important;
}
